import React from 'react';
import './SideBar.styles.js';
import { StyledTopBar, StyledContainerLeft, ImageContainer, TextKFZ, StyledAside } from './SideBar.styles.js';
import { Outlet } from "react-router-dom";

const SideBar = ({children}) => {
  return (
    <StyledTopBar>
    <StyledAside>
    <StyledContainerLeft>
        <ImageContainer></ImageContainer>
        <TextKFZ>KFZ</TextKFZ>
        </StyledContainerLeft>
        {/* <div style={{padding:".4rem", width:"100%"}}>
        <Button style={{width:"100%"}}>ToDo</Button>
        </div>
        <div style={{padding:".4rem", width:"100%"}}>
        <Button style={{width:"100%"}}>Fahrzeuge</Button>
        </div> */}

    

    </StyledAside>
        <div style={{ flex: 1 }}>
      <Outlet /> {/* Renders nested routes */}
    </div>
    </StyledTopBar>

  );
};

export default SideBar;