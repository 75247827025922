import React, { useState } from 'react';
import './HomePage.styles.js';
import { DataStore } from 'aws-amplify/datastore';
import { CarKFZ } from '../../models';
import { StyledHome, StyledContainerLeft, StyledContainerRight, StyledInput, StyledOverlayPanel, StyledSpan, StyledRowDiv, StyledButtonRound, StyledButtonRectangel, StyledCarPanel, StyledSpanDyn, Overlay } from './HomePage.styles.js';
import CenterMain from '../../components/CenterMain/CenterMain.js';

const HomePage = () => {
  const [showOverlay, setShowOverlay] = useState(false);
  const [items, setItems] = useState([]);
  const [formData, setFormData] = useState({
    besitzer:"",
    nummer:"",
    hersteller:"",
    modell:"",
    baujahr:0,
    kfz_kennzeichen: "", 
    code: "",
    kilometer: 0,
  });

  const addNewModel = async () => {
    await DataStore.save(
      new CarKFZ({
        besitzer:formData.besitzer,
        nummer:formData.nummer,
        hersteller:formData.hersteller,
        modell:formData.modell,
        baujahr: parseInt(formData.baujahr, 10),
        kfz_kennzeichen: formData.kfz_kennzeichen, 
        code: formData.code,
        kilometer:  parseInt(formData.kilometer, 10),
      })
    );
    console.log("New model added!");
  };

  const fetchAll = async () => {
    const items = await DataStore.query(CarKFZ);
    setItems(items);
  };

  const deleteItem = async (id) => {
    await DataStore.delete(CarKFZ, id);
    setItems((prevItems) => prevItems.filter((item) => item.id !== id)); // Remove from state
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const clearFormData = () => {
    setFormData({
      besitzer:"",
      nummer:"",
      hersteller:"",
      modell:"",
      baujahr:0,
      kfz_kennzeichen: "", 
      code: "",
      kilometer: 0,
    })
  }


  return (
    <StyledHome>
    <CenterMain>
    <StyledContainerRight  style={{marginBottom:"10px"}}>
      <h1>Fahrzeuge</h1>
      <StyledButtonRound onClick={()=>{setShowOverlay(true);
      fetchAll();
      }}>+</StyledButtonRound>
      </StyledContainerRight>

      <StyledContainerRight  style={{flex:"1", flexDirection:"column", overflow:"auto", boxSizing:"border-box", scrollbarWidth:"none"}}>
      {items.map((item) => (
        <StyledCarPanel style={{flexDirection:"row", justifyContent:"space-between"}} key={item.id}>
      <StyledContainerRight style={{flexDirection:"column", gap:"0"}}>
      <StyledSpanDyn style={{ color: "Black", fontSize: "20px", fontWeight: "bold"}}>{item.hersteller}</StyledSpanDyn>
      <StyledSpanDyn style={{ color: "Black", fontSize: "18px" }}>{item.code}</StyledSpanDyn>
      <StyledSpanDyn style={{ color: "Black", fontSize: "14px" }}>{item.baujahr}</StyledSpanDyn>
      </StyledContainerRight>
      <StyledContainerLeft>
        <StyledButtonRound style={{backgroundColor:"#D9D9D9", visibility:"hidden"}}>E</StyledButtonRound>
        <StyledButtonRound style={{backgroundColor:"#D9D9D9"}} onClick={() => deleteItem(item.id)}>X</StyledButtonRound>
      </StyledContainerLeft>
      </StyledCarPanel>
      ))}
      </StyledContainerRight>

      <Overlay show={showOverlay}>
      <StyledOverlayPanel>
      <StyledRowDiv>
      <StyledSpan>Fahrzeug Hinzufügen</StyledSpan>
      <StyledButtonRound onClick={() => setShowOverlay(false)}>X</StyledButtonRound>
      </StyledRowDiv>
      <span style={{color:"black", marginLeft:"5px", fontWeight:"bolder"}}>Besitzer</span>
        <StyledInput placeholder='Besitzer' name='besitzer' value={formData.besitzer} onChange={handleChange}></StyledInput>
        <span style={{color:"black", marginLeft:"5px", fontWeight:"bolder"}}>Nummer</span>
        <StyledInput placeholder='Nummer' name='nummer' value={formData.nummer} onChange={handleChange}></StyledInput>
        <span style={{color:"black", marginLeft:"5px", fontWeight:"bolder"}}>Hersteller</span>
        <StyledInput placeholder='Hersteller' name='hersteller' value={formData.hersteller} onChange={handleChange}></StyledInput>
        <span style={{color:"black", marginLeft:"5px", fontWeight:"bolder"}}>Modell</span>
        <StyledInput placeholder='Modell' name='modell' value={formData.modell} onChange={handleChange}></StyledInput>
        <span style={{color:"black", marginLeft:"5px", fontWeight:"bolder"}}>Baujahr</span>
        <StyledInput placeholder='Baujahr' type='number' name='baujahr' value={formData.baujahr} onChange={handleChange}></StyledInput>
        <span style={{color:"black", marginLeft:"5px", fontWeight:"bolder"}}>KFZ-Kennzeichen</span>
        <StyledInput placeholder='KFZ-Kennzeichen' name='kfz_kennzeichen' value={formData.kfz_kennzeichen} onChange={handleChange}></StyledInput>
        <span style={{color:"black", marginLeft:"5px", fontWeight:"bolder"}}>Code</span>
        <StyledInput placeholder='Code' name='code' value={formData.code} onChange={handleChange}></StyledInput>
        <span style={{color:"black", marginLeft:"5px", fontWeight:"bolder"}}>Kilometer</span>
        <StyledInput placeholder='Kilometer' type='number' name='kilometer' value={formData.kilometer} onChange={handleChange}></StyledInput>
        <StyledButtonRectangel onClick={()=> {addNewModel();
          clearFormData();
          fetchAll();
          setShowOverlay(false)
        }}>Speichern</StyledButtonRectangel>
      </StyledOverlayPanel>
    </Overlay>

    </CenterMain>
    </StyledHome>
  );
};

export default HomePage;
