import styled, { keyframes } from 'styled-components';

  export const StyledAuth = styled.div`
`;
export const StyledButton = styled.button`
  color: black;
  border: none;
  border-radius: 4px;
  padding: 9px;
  height: 35px;
  &:hover {
    background-color: red;
  }
`;

export const StyledContainerLeft = styled.div`
display: flex;
align-items: center;
`;
export const StyledContainerRight = styled.div`
display: flex;
align-items: center;
justify-content: center;
gap: .5em;
;
`;

export const ImageContainer = styled.div`
  width: 70px;
  height: 70px;
  background-image: url('./assets/logo.png');
  background-size: cover;
  background-position: center;
`;

const colorWave = keyframes`
   0% {
    background-position: 0%;
  }
  100% {
    background-position: 200%;
  }
`;
export const TextKFZ = styled.span`
  color: white;
  font-weight: bolder;
  font-style: italic;
  font-size: 1.9rem;
  margin-bottom: .1em;

  color: transparent;
  background: linear-gradient(90deg, #ff4e50, #fff, #ff4e50);
  background-size: 200%;
  -webkit-background-clip: text;
  font-weight: bold;
  background-clip: text;
  animation: ${colorWave} 5s linear infinite;
`;


export const StyledInput = styled.input`
  color: black;
  border: none;
  padding: 10px;
  border-radius: 4px;
  height: 35px;
`;