// @ts-check
import { initSchema } from '@aws-amplify/datastore';
import { schema } from './schema';



const { Car, CarKFZ, Customer, Reservation } = initSchema(schema);

export {
  Car,
  CarKFZ,
  Customer,
  Reservation
};