import styled, { keyframes } from 'styled-components';

  export const StyledHome = styled.div`
  display: flex;
  flex-direction: row;
`;
export const StyledButtonRound = styled.button`
  width: 50px; /* Adjust size */
  height: 50px;
  background-color: white; /* Button color */
  color: black; /* Text color */
  border: none;
  border-radius: 50%; /* Makes it a circle */
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px; /* Size of the "+" */
  cursor: pointer;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Optional shadow */
  transition: transform 0.2s ease, background-color 0.3s ease;
`;

export const StyledButtonRectangel = styled.button`
  color: black;
  border: none;
  border-radius: 4px;
  padding: 9px;
  height: 35px;
  margin: 5px;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    background-color: red;
  }
`;

export const StyledContainerLeft = styled.div`
display: flex;
`;
export const StyledContainerRight = styled.div`
display: flex;
flex-direction: row;
gap: .5em;
;
`;

export const StyledCarPanel = styled.div`
display: flex;
flex-direction: row;
height: 90px;
gap: .5em;
width: 100%;
background-color: #FFFFFF;
padding: 10px;
border-radius: 4px;
;
`;

export const ImageContainer = styled.div`
  width: 70px;
  height: 70px;
  background-image: url('./assets/logo.png');
  background-size: cover;
  background-position: center;
`;

const colorWave = keyframes`
   0% {
    background-position: 0%;
  }
  100% {
    background-position: 200%;
  }
`;
export const TextKFZ = styled.span`
  color: white;
  font-weight: bolder;
  font-style: italic;
  font-size: 1.9rem;
  margin-top: .3em;

  color: transparent;
  background: linear-gradient(90deg, #ff4e50, #fff, #ff4e50);
  background-size: 200%;
  -webkit-background-clip: text;
  font-weight: bold;
  background-clip: text;
  animation: ${colorWave} 5s linear infinite;
`;


export const StyledInput = styled.input`
  color: black;
  border: none;
  padding: 10px;
  margin: 5px;
  border-radius: 4px;
  height: 35px;
  background-color: #BDBDBD;
`;

export const StyledOverlayPanel = styled.div`
  background-color: #D9D9D9;
  border: none;
  padding: 10px;
  border-radius: 4px;

  width: 50%;
  display: flex;
  flex-direction: column;
`;

export const StyledSpan = styled.span`
  color: black;
  font-weight: bolder;
  font-size: 1.9rem;
  margin-top: .3em;
`;

export const StyledRowDiv = styled.div`
  display: flex;
  flex-direction: row;
`;

export const StyledSpanDyn = styled.span`
  color: ${(props) => props.color || "black"};
  font-size: ${(props) => props.size || "16px"};
  font-weight: ${(props) => (props.bold ? "bold" : "normal")};
`;


export const Overlay = styled.div`
  display: ${(props) => (props.show ? "flex" : "none")};
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;