import React from 'react';
import './CenterMain.styles.js';
import { StyledMainContainer } from './CenterMain.styles.js';

const CenterMain = ({children}) => {
  return (
    <StyledMainContainer>
           {children}
    </StyledMainContainer>
  );
};

export default CenterMain;