import React from 'react';
import './TopBar.styles.js';
import { StyledTopBar } from './TopBar.styles.js';

const TopBar = ({children}) => {
  return (
    <StyledTopBar>
           {children}
    </StyledTopBar>
  );
};

export default TopBar;