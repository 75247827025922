import {React, useState, useEffect}  from 'react';
import { BrowserRouter as Router, Route,Routes, Navigate  } from 'react-router-dom';
import './App.css';
import AuthPage from './pages/AuthPage/AuthPage';
import { getCurrentUser } from 'aws-amplify/auth'
import { Amplify } from 'aws-amplify';
import '@aws-amplify/ui-react/styles.css';
import config from './amplifyconfiguration.json';
import HomePage from './pages/Home/HomePage';
import SideBar from './components/SideBar/SideBar';
Amplify.configure(config);

const  App = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(null); // Replace with your actual auth logic
  useEffect( () => {
    const checkUser = async () => {
      try {
        await getCurrentUser();
        setIsAuthenticated(true);
      } catch (err) {
        setIsAuthenticated(false);
      }
    };

    checkUser(); 
  }, []);
  if (isAuthenticated === null) {
    return <h1>Loading...</h1>;
  }
  return (
    <Router>
    <Routes>
      <Route path="/" element={isAuthenticated ? <Navigate replace to="/home" /> :<AuthPage />} />
      <Route element={<SideBar />}>
          <Route path="/home" element={isAuthenticated ? <HomePage /> :<Navigate replace to="/" />} />
        </Route>
      {/* <Route path="/home" element={isAuthenticated ? <HomePage /> :<Navigate replace to="/" />} /> */}
      {/* <Route path="*" element={isAuthenticated ? <Navigate replace to="/home" /> :<Navigate replace to="/home" />} /> */}
    </Routes>
  </Router>
  );
}

export default App;