import styled, { keyframes } from 'styled-components';

  export const StyledTopBar = styled.div`
    display: flex;
`;

export const StyledAside = styled.aside`
background-color: #000000;
color: white;
display: flex;
flex-direction: column;
width: 10rem;
align-items: center;
`;

export const StyledContainerLeft = styled.div`
display: flex;
`;

export const ImageContainer = styled.div`
  width: 70px;
  height: 70px;
  background-image: url('./assets/logo.png');
  background-size: cover;
  background-position: center;
`;
const colorWave = keyframes`
   0% {
    background-position: 0%;
  }
  100% {
    background-position: 200%;
  }
`;
export const TextKFZ = styled.span`
  color: white;
  font-weight: bolder;
  font-style: italic;
  font-size: 1.9rem;
  margin-top: .3em;

  color: transparent;
  background: linear-gradient(90deg, #ff4e50, #fff, #ff4e50);
  background-size: 200%;
  -webkit-background-clip: text;
  font-weight: bold;
  background-clip: text;
  animation: ${colorWave} 5s linear infinite;
`;

export const Button = styled.button`
  background-color: #373737; /* Grüner Hintergrund */
  color: white; /* Weißer Text */
  border: none; /* Kein Rand */
  border-radius: 8px; /* Abgerundete Ecken */
  padding: 12px 20px; /* Innenabstand */
  font-size: 16px; /* Schriftgröße */
  cursor: pointer; /* Zeiger beim Hover */
  transition: background-color 0.3s ease, transform 0.2s ease; /* Sanfte Übergänge */
  
  &:hover {
    background-color: #2E2E2E; /* Etwas dunklerer Grünton */
    transform: scale(1.05); /* Leichtes Vergrößern beim Hover */
  }

  &:active {
    transform: scale(0.95); /* Leichtes Verkleinern beim Klick */
    background-color: #1D1D1D; /* Noch dunkler beim Klick */
  }
`;