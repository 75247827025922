import React, { useState } from 'react';
import './AuthPage.styles.js';
import { signIn } from 'aws-amplify/auth'
import {  useNavigate } from "react-router-dom";
import TopBar from '../../components/TopBar/TopBar.js';
import { ImageContainer, StyledAuth, StyledButton, StyledContainerLeft, StyledContainerRight, StyledInput, TextKFZ } from './AuthPage.styles.js';
import CenterMain from '../../components/CenterMain/CenterMain.js';

const AuthPage = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();
  async function handleSignIn() {
    try {
      await signIn({
        username: username,
        password: password,
      });
      navigate('/home');
    } catch (e) {
      console.log(`Error: ${e.message}`);
      const str = e.message;
      if (str.includes("already a signed")) {
        navigate('/home');
      }
    }
  }
  return (
    <StyledAuth>
    <TopBar>
        <StyledContainerLeft>
        <ImageContainer></ImageContainer>
        <TextKFZ>KFZ</TextKFZ>
        </StyledContainerLeft>
        <StyledContainerRight>
        <StyledInput type="benutzername" placeholder="Benutzername"    value={username}
        onChange={(e) => setUsername(e.target.value)}/>
        <StyledInput type="password" placeholder="Passwort"   value={password}
        onChange={(e) => setPassword(e.target.value)}/>
        <StyledButton type="anmelden" onClick={handleSignIn}>Anmelden</StyledButton>
        </StyledContainerRight>
    </TopBar>
    <CenterMain>
      <h1>KFZ Verwaltungssystem</h1>
      <span>Das Werkstattverwaltungssystem ermöglicht Funktionen wie das Hinzufügen von Fahrzeugen.</span>
    </CenterMain>
    </StyledAuth>
  );
};

export default AuthPage;
