import styled from 'styled-components';

  export const StyledMainContainer = styled.div`
    background-color: #585858;
    color: white;
    display: flex;

    width: 100%;
    height: 100vh;
    padding: 2rem;
    flex-direction: column;
`;